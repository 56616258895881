import React from "react";


function RenderByArticleType({item}) {
  if(item.type === "single"){
    return(
      <SingleImageArticle item={item} />
    );
  }else {
    return(
      <MultiImageArticle item={item} />
    );
  }
}

const SingleImageArticle = ({item}) => (
  <div className="bg-white shadow-lg rounded-lg p-4 m-2 blogCart">
    <div className="flex justify-between">
      <h2 className="text-lg text-gray-800 font-bold">{item.title}</h2>
      <div className="text-sm text-gray-600 block lg:hidden">
        <span className="text-sm text-gray-600">{item.date[0]}. {item.date[1]} </span>
      </div>
    </div>
    <div className="block lg:flex mt-4">
      <div className="lg:w-24 lg:h-24 flex-none mb-4">
          <img alt="article cover" src={item.images[0].path} className="rounded-lg" />
      </div>
      <div className="w-full pl-4 pr-4">
        <p className="text-gray-600 text-md">{item.text}</p>
      </div>
      <div className="w-32 border-l-2 p-2 text-center hidden lg:block relative">
        <span className="text-md text-gray-600 block">{item.date[1]}</span>
        <span className="text-3xl text-gray-800 block leading-7">{item.date[0]}</span>
        <span className="text-md text-gray-600 block">{item.date[2]}</span>
        <div className="blogCartMore invisible absolute bottom-0 right-0">
          <i className="fas fa-arrow-right align-baseline"></i>
        </div>
      </div>
    </div>
  </div>
);

const MultiImageArticle = ({item}) => (
  <div className="bg-white shadow-lg lg:rounded-lg p-4 m-2 blogCart">
    <div className="flex justify-between">
      <h2 className="text-lg text-gray-800 font-bold">{item.title}</h2>
      <div className="text-sm text-gray-600 block lg:hidden">
        <span className="text-sm text-gray-600">{item.date[0]}. {item.date[1]} </span>
      </div>
    </div>
    <div className="flex mt-4">
      <div className="w-full pr-4">
        <p className="text-gray-600 text-md">{item.text}</p>
        <div className="flex mt-4">
          {item.images.map(function (img, i) {
						return (
              <div key={i} className="w-24 h-20 mr-4">
                <img alt="Article visuals" src={img.path} className="rounded-lg" />
              </div>
            )
					})}
        </div>
      </div>
      <div className="w-32 border-l-2 p-2 text-center hidden lg:block relative">
        <span className="text-md text-gray-600 block">{item.date[1]}</span>
        <span className="text-3xl text-gray-800 block leading-7">{item.date[0]}</span>
        <span className="text-md text-gray-600 block">{item.date[2]}</span>
        <div className="blogCartMore invisible absolute bottom-0 right-0">
          <i className="fas fa-arrow-right align-baseline"></i>
        </div>
      </div>
    </div>
  </div>
);


export default RenderByArticleType;
