import React from "react";
import Menu from "../util/Menu";
import Footer from "../util/Footer";
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import ArticleRenderer from "../../components/ArticleRenderer";
import articles from "../../articles/articleTeasers.json";
import Animations from '../../components/animations/Animations';
import SingleArticle from './SingleArticle';

export default function Blog(props) {
  return (
    <Switch>
     <Route exact path='/blog' component={FullRoster}/>
     <Route path='/blog/:number' component={SingleArticle}/>
   </Switch>
  );
}


const FullRoster = () => (
  <div>
    <Animations />
    <div className="bg-gray-800 min-h-full">
      <div className="flex">
        <div className="w-1/5">
        </div>
        <div className="w-3/5">
          <Menu />
        </div>
        <div className="w-1/5">
        </div>
      </div>

      <div className="ml-12 p-10">
        <h1 className="text-4xl text-white font-bold">
          Projects of Interest
        </h1>
        <h3 className="text-gray-400 h-32">
          No matter what I do or where I go<br/>
          I’m always 100% in and follow my projects with passion!<br/>
          Below you can find a List of my passion Projects.
        </h3>
      </div>
    </div>
    <div className="splitterTop"></div>
    <div className="ml-12 p-10">
      <div className="max-w-4xl mb-2">
        <Link to="/blog/website">
            <ArticleRenderer item={articles.website} />
        </Link>
      </div>
      <div className="max-w-4xl mb-2">
        <Link to="/blog/office">
            <ArticleRenderer item={articles.office} />
        </Link>
      </div>
      <div className="max-w-4xl mb-2">
        <Link to="/blog/fishtank">
            <ArticleRenderer item={articles.fishtank} />
        </Link>
      </div>
      <div className="max-w-4xl mb-2">
        <Link to="/blog/greensock">
            <ArticleRenderer item={articles.greensock} />
        </Link>
      </div>
    </div>
    <Footer type="splitter"/>
  </div>
)
