import React from "react";
import Lefthex from "../../components/Lefthex";
import Rightex from "../../components/Righthex";
import Menu from "../util/Menu";

const BlogSegment = () => (
  <div>
    <div className="headerSegment bg-gray-800">
      <div className="flex overflow-hidden">
        <div className="w-1/5">
          <Lefthex />
        </div>
        <div className="w-3/5">
          <Menu />
          <div className="pt-32">
            <div className="text-4xl text-white font-bold">
              <h1 className="introtext-1">Hi!</h1>
              <h1 className="introtext-2">I’m Leon</h1>
              <h1 className="introtext-3">Developer at <span className="text-red-600">♥</span></h1>
            </div><br/>
            <h3 className="text-white introtext-4">Learn more about me & Check out what drives me</h3>
          </div>
          <div className="timeLineIntro"></div>
          <div className="timeLineIntroScrolled"></div>
        </div>
        <div className="w-1/5">
          <Rightex />
        </div>
      </div>
    </div>
    <div className="splitterTop"></div>
  </div>
);

export default BlogSegment;
