import React from "react";
import axios from "axios"

class ContactForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        email: "",
        error: "",
        isLoading: false,
        isSend: false
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

  }

  validate(){
      let email = this.state.email;
      let error = "";
      let isValid = true;

      if (!email || email === "") {
        isValid = false;
        error = "Please enter your email.";
      }

      if (email !== "") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          isValid = false;
          error = "Please enter a valid email address.";
        }
      }

      this.setState({
        error: error
      });

      return isValid;
  }

  handleChange(event) {
    let email = event.target.value;

    this.setState({
      email: email
    });
  }

  handleSubmit(e){
    e.preventDefault();
    if(this.validate()){
      this.setState({
        isLoading: true
      });
      axios({
        method: "POST",
        url:"/.netlify/functions/addNewsletterMail",
        data:  this.state.email
      }).then((response)=>{
        if (response.data.status === 'success'){
            this.resetForm()
            this.setState({
              isLoading: false,
              isSend: true
            });
        }else if(response.data.status === 'fail'){
            alert("Error in adding your Email.")
            this.setState({
              isLoading: false
            });
        }
      })
    }
  }

  resetForm(){
    this.setState({
      email: ""
    });
  }

  render() {
    console.log(this.state);
    if(this.state.isSend){
      return(
        <div className="block text-blue-500 text-m h-10 mt-2 font-bold">Thank you, I’ll let you know when a new article is published.<br/></div>
      );
    } else {
      return(
        <div className="mt-10 mb-24">
          <form
            className="w-full max-w-lg"
            onSubmit={this.handleSubmit}
          >
            <input
              className="py-3 px-4 bg-gray-300 w-48 sm:w-64 text-gray-800 outline-none placeholder-gray-500 focus:bg-gray-200 rounded-lg rounded-r-none"
              type="text"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Enter your email" />
            {this.state.isLoading ?
              <button className="opacity-50 cursor-not-allowed py-3 px-4 bg-gray-700 text-gray-100 font-semibold hover:bg-gray-600 rounded-lg rounded-l-none">
                <i className="fas fa-circle-notch fa-spin"></i>
              </button>
              :
              <button className="py-3 px-4 bg-gray-700 text-gray-100 font-semibold hover:bg-gray-600 rounded-lg rounded-l-none">Subscribe</button>
            }
          </form>
        </div>
      );
    }
  };


}


export default ContactForm;
