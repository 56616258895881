import React from "react";

function RightHex(props) {
  return(
    <div className={props.type === "bottom" ? "RightHexagonsWrapperBottom" : "RightHexagonsWrapper"}>
      <svg viewBox="0 0 677.114 935.29">
        <defs>
          <filter id="Polygon_9" x="362" y="210.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_11" x="121" y="210.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-2"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Pfad_78" x="1.774" y="278.29" width="192.452" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-3"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-3"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_17" x="1" y="140.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-4"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-4"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_31" x="480.886" y="0" width="196.229" height="176.581" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-5"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-5"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_43" x="362" y="72.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-6"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-6"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_44" x="483" y="280.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-7"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-7"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_45" x="241" y="278.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-8"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-8"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_46" x="241" y="416.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-9"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-9"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_49" x="121" y="623.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-10"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-10"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_50" x="0" y="554.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-11"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-11"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_51" x="241" y="554.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-12"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-12"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_52" x="362" y="623.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-13"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-13"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Polygon_53" x="362" y="761.29" width="194" height="174" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="7.5" result="blur-14"/>
            <feFlood floodColor="#3b8ef0" floodOpacity="0.627"/>
            <feComposite operator="in" in2="blur-14"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="Gruppe_43" data-name="Gruppe 43" transform="translate(-1370.448 71.79)">
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_9)">
            <g id="Polygon_9-2" data-name="Polygon 9" transform="translate(384.5 229.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_11)">
            <g id="Polygon_11-2" data-name="Polygon 11" transform="translate(143.5 229.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Pfad_78)">
            <g id="Pfad_78-2" data-name="Pfad 78" transform="translate(23.5 297.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_17)">
            <g id="Polygon_17-2" data-name="Polygon 17" transform="translate(23.5 159.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1318817138672 121 L 139.7617340087891 64.49999237060547 L 107.1318588256836 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636474609375 0 C 110.6497573852539 0 112.3002014160156 0.9527587890625 113.1934661865234 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934661865234 126.5005493164062 C 112.3002014160156 128.0472412109375 110.6497573852539 129 108.8636474609375 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_31)">
            <g id="Polygon_31-2" data-name="Polygon 31" transform="matrix(1, -0.02, 0.02, 1, 503.39, 22.1)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_43)">
            <g id="Polygon_43-2" data-name="Polygon 43" transform="translate(384.5 91.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_44)">
            <g id="Polygon_44-2" data-name="Polygon 44" transform="translate(505.5 299.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_45)">
            <g id="Polygon_45-2" data-name="Polygon 45" transform="translate(263.5 297.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_46)">
            <g id="Polygon_46-2" data-name="Polygon 46" transform="translate(263.5 435.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_49)">
            <g id="Polygon_49-2" data-name="Polygon 49" transform="translate(143.5 642.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_50)">
            <g id="Polygon_50-2" data-name="Polygon 50" transform="translate(22.5 573.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_51)">
            <g id="Polygon_51-2" data-name="Polygon 51" transform="translate(263.5 573.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_52)">
            <g id="Polygon_52-2" data-name="Polygon 52" transform="translate(384.5 642.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1370.45, -71.79)" filter="url(#Polygon_53)">
            <g id="Polygon_53-2" data-name="Polygon 53" transform="translate(384.5 780.79)" fill="none">
              <path d="M108.864,0a5,5,0,0,1,4.33,2.5L147.556,62a5,5,0,0,1,0,5l-34.362,59.5a5,5,0,0,1-4.33,2.5H40.136a5,5,0,0,1-4.33-2.5L1.444,67a5,5,0,0,1,0-5L35.807,2.5A5,5,0,0,1,40.136,0Z" stroke="none"/>
              <path d="M 41.86810302734375 8 L 9.238265991210938 64.5 L 41.86813354492188 121 L 107.1319046020508 121 L 139.7617340087891 64.49999237060547 L 107.1318817138672 8 L 41.86810302734375 8 M 40.13633728027344 0 L 108.8636779785156 0 C 110.6497802734375 0 112.3002319335938 0.9527587890625 113.1934967041016 2.49945068359375 L 147.5558929443359 61.99945068359375 C 148.449462890625 63.54673767089844 148.449462890625 65.45326232910156 147.5558929443359 67.00054931640625 L 113.1934967041016 126.5005493164062 C 112.3002319335938 128.0472412109375 110.6497802734375 129 108.8636779785156 129 L 40.13630676269531 129 C 38.35022735595703 129 36.69975280761719 128.0472412109375 35.8065185546875 126.5005493164062 L 1.444122314453125 67.00054931640625 C 0.5505218505859375 65.45326232910156 0.5505218505859375 63.54673767089844 1.444122314453125 61.99945068359375 L 35.8065185546875 2.49945068359375 C 36.69975280761719 0.9527587890625 38.35022735595703 0 40.13633728027344 0 Z" stroke="none" fill="#3b8ef0"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
};

export default RightHex;
