import React from "react";
import axios from "axios"

class ContactForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        input: {},
        errors: {},
        isLoading: false,
        isSend: false
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

  }

  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;

      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Please enter your name.";
      }

      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }

      if (typeof input["email"] !== "undefined") {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }

      if (!input["message"]) {
        isValid = false;
        errors["message"] = "You need to write at least something...";
      }

      this.setState({
        errors: errors
      });

      return isValid;
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input
    });
  }

  handleSubmit(e){
    e.preventDefault();
    if(this.validate()){
      this.setState({
        isLoading: true
      });
      axios({
        method: "POST",
        url:"/.netlify/functions/append-airtable",
        data:  this.state.input
      }).then((response)=>{
        if (response.data.status === 'success'){
            this.resetForm()
            this.setState({
              isLoading: false,
              isSend: true
            });
        }else if(response.data.status === 'fail'){
            alert("Message failed to send.")
            this.setState({
              isLoading: false
            });
        }
      })
    }
  }

  resetForm(){
    this.setState({
      input:{
        email: "",
        name: "",
        message: ""
      }
    });
  }

  render() {
    return(
      <div className="mt-32 mb-24">
        <form
          className="w-full max-w-lg"
          onSubmit={this.handleSubmit}
        >
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 mb-2 md:mb-0 md:px-3">
              <label className="block uppercase text-gray-500 text-s font-bold mb-1" htmlFor="grid-first-name">
                Name
              </label>
              <input
                className="appearance-none mb-1 block w-full bg-gray-400 text-gray-700 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                name="name"
                value={this.state.input.name}
                onChange={this.handleChange}
              />
            <div className="block text-orange-500 text-s h-2 mb-2">{this.state.errors.name}</div>
            </div>
            <div className="w-full md:w-1/2 md:px-3">
              <label className="block uppercase text-gray-500 text-s font-bold mb-1" htmlFor="grid-last-name">
                E-mail
              </label>
              <input
                className="appearance-none mb-1 block w-full bg-gray-400 text-gray-700 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                name="email"
                value={this.state.input.email}
                onChange={this.handleChange}
              />
            <div className="block text-orange-500 text-s h-2 mb-2">{this.state.errors.email}</div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:px-3">
              <label className="block uppercase text-gray-500 text-s font-bold mb-1" htmlFor="grid-password">
                Tell me something...
              </label>
              <textarea
                className=" no-resize appearance-none block w-full bg-gray-400 text-gray-700 border border-gray-700 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                name="message"
                value={this.state.input.message}
                onChange={this.handleChange}
              >
              </textarea>
              <div className="block text-orange-500 text-s h-2 mb-2">{this.state.errors.message}</div>
            </div>
          </div>
          <div>
            {this.state.isLoading ?
              <button className="opacity-50 cursor-not-allowed bg-gray-400 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                <i className="fas fa-circle-notch fa-spin"></i>
              </button>
              :
                <button className="bg-gray-400 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <span className="mr-4">Send</span>
                  <i className="far fa-paper-plane"></i>
                </button>
            }
            {this.state.isSend ?
              <div className="block text-blue-500 text-s h-2 mt-2">Thank you for the Message!<br/> I will get back to you soon.</div>
              :
              ""
            }

          </div>
        </form>
      </div>
    );
  };


}


export default ContactForm;
