import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './containers/home/Home';
import Blog from './containers/blog/Blog';
import About from './containers/about/About';
import Legal from './containers/util/Legal';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/legal">
          <Legal />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

//
// Fishtanks are an amazing source for stressreleaf.
// To see your tank living, growing and evolving is truly an amazing experiance.
// I was always enthusiastic about fishtanks, since I was 11 I had a tank in my room and
// recently I thought It was time for an upgrade. I got myself a Nanocube.
