import React from "react";
import { Link } from "react-router-dom";

// class Menu extends React.Component {
//
//   state = {
//     showMenu: false,
//   }
//
//   toggleMenu = () => {
//     this.setState({
//       showMenu: !this.state.showMenu
//     })
//   }
//
//   render() {
//     console.log(this.state.showMenu);
//     return (
//       <div className="flex justify-center">
//         <a class={ this.state.showMenu ? "button toggled" : "button" } onClick={this.toggleMenu}><span></span><span></span><span></span></a>
//       </div>
//     )
//   }
// };

const Menu = () => (
    <div className="flex justify-center">
      <div className="menuItems mt-4 relative">
        <Link
          to="/"
          className="text-gray-400 sm:p-1 mr-6 hover:text-gray-100 text-lg font-bold js-work-link"
        >
          <span className="js-an-word">Home</span>
          <span className="underline"></span>
        </Link>
        <Link
          to="/about"
          className="text-gray-400 sm:p-1 mr-6 hover:text-gray-100 text-lg font-bold js-work-link"
        >
          <span className="js-an-word">About Me</span>
          <span className="underline"></span>
        </Link>
        <Link
          to="/blog"
          className="text-gray-400 sm:p-1 mr-6 hover:text-gray-100 text-lg font-bold js-work-link"
        >
          <span className="js-an-word">Blog</span>
          <span className="underline"></span>
        </Link>
      </div>
    </div>
);



export default Menu;
