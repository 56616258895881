import React from "react";
const LeftHex = () => (
  <div className="LeftHexagonsWrapper">
    <svg viewBox="0 0 774.481 1142.731">
      <defs>
        <filter id="Polygon_20" x="147" y="771" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_21" x="74" y="643" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-2"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-2"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_22" x="221" y="643" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-3"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-3"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_23" x="0" y="771" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-4"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-4"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_24" x="147" y="513" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-5"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-5"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_25" x="73" y="384" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-6"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-6"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_26" x="147" y="256" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-7"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-7"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_27" x="297" y="256" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-8"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-8"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_28" x="373" y="128" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-9"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-9"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_29" x="299" y="0" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-10"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-10"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_41" x="221" y="900" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-11"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-11"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_42" x="371" y="643" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-12"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-12"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="Polygon_54" x="524" y="128" width="250.481" height="242.731" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="7.5" result="blur-13"/>
          <feFlood floodColor="#cc7a09" floodOpacity="0.624"/>
          <feComposite operator="in" in2="blur-13"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g className="lhex" id="Gruppe_42" data-name="Gruppe 42" transform="translate(98.355 94.542)">
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_20)">
          <g id="Polygon_20-2" data-name="Polygon 20" transform="matrix(0.87, 0.5, -0.5, 0.87, 238, 790.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_21)">
          <g id="Polygon_21-2" data-name="Polygon 21" transform="matrix(0.87, 0.5, -0.5, 0.87, 165, 662.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_22)">
          <g id="Polygon_22-2" data-name="Polygon 22" transform="matrix(0.87, 0.5, -0.5, 0.87, 312, 662.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_23)">
          <g id="Polygon_23-2" data-name="Polygon 23" transform="matrix(0.87, 0.5, -0.5, 0.87, 91, 790.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_24)">
          <g id="Polygon_24-2" data-name="Polygon 24" transform="matrix(0.87, 0.5, -0.5, 0.87, 238, 532.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_25)">
          <g id="Polygon_25-2" data-name="Polygon 25" transform="matrix(0.87, 0.5, -0.5, 0.87, 164, 403.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_26)">
          <g id="Polygon_26-2" data-name="Polygon 26" transform="matrix(0.87, 0.5, -0.5, 0.87, 238, 275.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_27)">
          <g id="Polygon_27-2" data-name="Polygon 27" transform="matrix(0.87, 0.5, -0.5, 0.87, 388, 275.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_28)">
          <g id="Polygon_28-2" data-name="Polygon 28" transform="matrix(0.87, 0.5, -0.5, 0.87, 464, 147.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_29)">
          <g id="Polygon_29-2" data-name="Polygon 29" transform="matrix(0.87, 0.5, -0.5, 0.87, 390, 19.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_41)">
          <g id="Polygon_41-2" data-name="Polygon 41" transform="matrix(0.87, 0.5, -0.5, 0.87, 312, 919.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_42)">
          <g id="Polygon_42-2" data-name="Polygon 42" transform="matrix(0.87, 0.5, -0.5, 0.87, 462, 662.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, -98.36, -94.54)" filter="url(#Polygon_54)">
          <g id="Polygon_54-2" data-name="Polygon 54" transform="matrix(0.87, 0.5, -0.5, 0.87, 615, 147.5)" fill="none">
            <path d="M115.742,0a5,5,0,0,1,4.33,2.5L156.729,66a5,5,0,0,1,0,5l-36.657,63.5a5,5,0,0,1-4.33,2.5H42.43a5,5,0,0,1-4.33-2.5L1.443,71a5,5,0,0,1,0-5L38.1,2.5A5,5,0,0,1,42.43,0Z" stroke="none"/>
            <path d="M 44.16207122802734 8 L 9.237274169921875 68.49999237060547 L 44.16207885742188 129 L 114.0096664428711 129 L 148.9344787597656 68.50000762939453 L 114.0096969604492 8 L 44.16207122802734 8 M 42.42990112304688 0 L 115.7418746948242 0 C 117.5282897949219 0 119.1790161132812 0.9531097412109375 120.0721282958984 2.500244140625 L 156.7287445068359 66.00027465820312 C 157.6216888427734 67.54714202880859 157.6216888427734 69.45285797119141 156.7287445068359 70.99974822998047 L 120.0721282958984 134.4997253417969 C 119.1790161132812 136.0468902587891 117.5282897949219 137 115.7418518066406 137 L 42.42987823486328 137 C 40.64346313476562 137 38.99273681640625 136.0468902587891 38.09960174560547 134.4997253417969 L 1.443008422851562 70.99972534179688 C 0.5500640869140625 69.45285797119141 0.5500640869140625 67.54714202880859 1.443008422851562 66.00025177001953 L 38.09962463378906 2.500244140625 C 38.99273681640625 0.9531097412109375 40.64346313476562 0 42.42990112304688 0 Z" stroke="none" fill="#f7891b"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
//
// const LeftHex = () => (
//   <div>
//     <div className="hexNo1">
//       <Hexagon />
//     </div>
//     <div className="hexNo2">
//       <Hexagon />
//     </div>
//     <div className="hexNo3">
//       <Hexagon />
//     </div>
//     <div className="hexNo4">
//       <Hexagon />
//     </div>
//     <div className="hexNo5">
//       <Hexagon />
//     </div>
//     <div className="hexNo6">
//       <Hexagon />
//     </div>
//     <div className="hexNo7">
//       <Hexagon />
//     </div>
//     <div className="hexNo8">
//       <Hexagon />
//     </div>
//   </div>
// );

export default LeftHex;
