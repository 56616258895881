import React, {useEffect} from "react";
import {gsap, Power3, TimelineMax, SteppedEase} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function Animations() {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    HomeEntryTimeline();
    HomeScrollTimeline();
    MenuHoverAnimation();
    AboutTerminalAnimation();

    const fadeIn = document.querySelectorAll(".animatedFadeIn");
    fadeIn.forEach((item) => {
      gsap.from(item, {
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=100px",
            toggleActions: "play none none reverse"
          },
          opacity: 0,
          ease: Power3.easeIn,
          delay: .3,
          yPercent: 10
      });
    })

  })

  return(
    <div></div>
  )
}

function AboutTerminalAnimation(){
  var timell = new TimelineMax({
    paused:true,
    defaultEase: SteppedEase.config(35)
  });

  timell.fromTo(".anim-typewriter", 2, {
      width: "0",
    }, {
      width: "200px",
    }, 0)

    .fromTo(".anim-typewriter2", 3, {
      width: "0",
    }, {
      width: "500px",
    }, 2)

    .fromTo(".anim-typewriter3", 2, {
      width: "0",
    }, {
      width: "600px",
    }, 5)

    .fromTo(".anim-typewriter4", 4, {
      width: "0",
    }, {
      width: "600px"
    }, 7)

    .fromTo(".anim-typewriter5", 4, {
      width: "0",
    }, {
      width: "600px"
    }, 11)

    .fromTo(".anim-typewriter6", 3, {
      width: "0",
    }, {
      width: "600px"
    }, 15);

  timell.play();
}

function MenuHoverAnimation() {

  function enterAnimation(link, e, index) {
    link.tl.tweenFromTo(0, "midway");
  }
  function leaveAnimation(link, e) {
    link.tl.play();
  }


  // Get all links
  let workLinks = document.querySelectorAll(".js-work-link");

  workLinks.forEach((link, index, value) => {

    let underline = link.querySelector(".underline");
    link.tl = gsap.timeline({paused: true});

    link.tl.fromTo(underline, {
      width: "0%",
      left: "0%",
    }, {
      width: "100%",
      duration: 0.7,
    });

    link.tl.add("midway");

    link.tl.fromTo(underline, {
      width: "100%",
      left: "0%",
    }, {
      width: "0%",
      left: "100%",
      duration: 0.7,
      immediateRender: false
    });

    // Mouseenter
    link.addEventListener("mouseenter", (e) => {
      enterAnimation(link, e, index);
    });

    // Mouseleave
    link.addEventListener("mouseleave", (e) => {
      leaveAnimation(link, e);
    });

  });
}

function HomeScrollTimeline(){
  gsap.from(".EntryAnimatedFadeIn", {
      scrollTrigger: {
        trigger: ".EntryAnimatedFadeIn",
        start: "top bottom-=170px",
        toggleActions: "play none none reverse"
      },
      opacity: 0,
      ease: Power3.easeIn,
      delay: .3,
      yPercent: 10
  });
  gsap.to(".timeLineIntroScrolled", {
    scrollTrigger: {
      trigger: ".timeLineIntroScrolled",
      scrub: true,
      start: "bottom 30%",
      end: "bottom top"
    },
    transformOrigin: "top bottom",
    ease: "none",
    height: 500
  });

  gsap.from(".blogIntro", {
    scrollTrigger: {
      trigger: ".blogIntro",
      start: "bottom 80%",
      end: "bottom 70%"
    },
    opacity: 0,
    ease: Power3.easeIn
  });

  gsap.to(".timeLineBlog", {
    scrollTrigger: {
      trigger: ".splitterTop",
      scrub: true,
      start: "bottom 52%",
      end: "top top"
    },
    transformOrigin: "top bottom",
    ease: "none",
    height: 300
  });

  gsap.to(".timeLineBlogBefore", {
    scrollTrigger: {
      trigger: ".displayArticle",
      scrub: true,
      start: "bottom 70%",
      end: "center 45%"
    },
    width: 200
  });
  gsap.from(".timelineBlogCenter", {
    scrollTrigger: {
      trigger: ".timeline",
      scrub: true,
      start: "top 30%",
      end: "bottom center"
    },
    height: 0
  });
  gsap.to(".timelineEnd", {
    scrollTrigger: {
      trigger: ".timeline",
      scrub: true,
      start: "bottom 80%",
      end: "bottom 1%"
    },
    width: "100%"
  });

  // Display Single Articles
  gsap.to(".displayArticle", {
    scrollTrigger: {
      trigger: ".displayArticle",
      scrub: true,
      start: "top 80%",
      end: "top 70%"
    },
    opacity: 1,
    margin: 0,
    ease: Power3.easeIn
  });
  gsap.to(".displayArticle2", {
    scrollTrigger: {
      trigger: ".displayArticle2",
      scrub: true,
      start: "top center",
      end: "top 45%"
    },
    opacity: 1,
    left: "50%"
  });
  gsap.to(".displayArticle3", {
    scrollTrigger: {
      trigger: ".displayArticle3",
      scrub: true,
      start: "top 68%",
      end: "top 60%"
    },
    opacity: 1,
    margin: 0
  });
  gsap.to(".displayArticle4", {
    scrollTrigger: {
      trigger: ".displayArticle4",
      scrub: true,
      start: "top 75%",
      end: "top 60%"
    },
    opacity: 1,
    left: "50%"
  });
}

function HomeEntryTimeline(){
  var tl = new TimelineMax({
          repeatDelay:0.5, // temp
          repeat:0,  // temp
  });

  tl.to(".introtext-1", .8,
    {
      opacity: 1,
      ease: Power3.easeIn,
      delay: .2
    }
  ).to(".introtext-2", .8,
    {
      opacity: 1,
      ease: Power3.easeIn,
    },
    "-=0.20"
  ).to(".introtext-3", .8,
    {
      opacity: 1,
      ease: Power3.easeIn,
    },
    "-=0.20"
  ).to(".introtext-4", .8,
    {
      opacity: 1,
      ease: Power3.easeIn,
    },
    "-=0.20"
  ).to(".timeLineIntro", .5,
     {
      opacity: 1,
      ease: Power3.easeIn,
     },
     "-=0.20"
  ).fromTo(".timeLineIntro", 3,
     {
      height: 0,
      immediateRender: true,
      autoRound: false,
      ease: Power3.easeNone
     },
     {
      height: 100,
     },
     "-=0.20"
  );

}

export default Animations;
