import React from "react";
import Menu from "../util/Menu";
import Footer from "../util/Footer";
import marked from 'marked'
import articles from "../../articles/articleTeasers.json"

export default class SingleArticle extends React.Component {
  constructor(props) {
    super(props)
    var pageURL = window.location.href;
    var articleName = pageURL.substr(pageURL.lastIndexOf('/') + 1);

    this.state = {
      markdown: "null",
      articleName: articleName,
      title: "",
      error: false
    }
  }

  componentDidMount() {
    var readmePath;
    try {
      readmePath = require("../../articles/"+this.state.articleName+".md");
    } catch (e) {
      console.log("nooo");
    }

    try {
      this.setState({
        title: articles[this.state.articleName].title
      })
    } catch (e) {
      this.setState({error:true})
    }

    fetch(readmePath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: marked(text)
        })
      })
  }

// <span className="inline-block align-baseline flex-none ml-4 text-xs bg-transparent text-gray-200 px-2 border border-orange-500 rounded">Lifestyle</span>


  render(){
    const { markdown } = this.state;

    return(
      <div className="h-full">
        <div className="bg-gray-800">
          <div className="flex">
            <div className="w-1/5">
            </div>
            <div className="w-3/5">
              <Menu />
            </div>
            <div className="w-1/5">
            </div>
          </div>

          <div className="ml-12 p-10">
            <div className="h-24">
            <div className="flex items-center">
              <h1 className="inline-block text-4xl text-white font-bold">{this.state.title !== "" ? this.state.title : "No Article"}</h1>
            </div>
            <div className="articleSpacer mb-2"></div>
            </div>
          </div>
        </div>
        <div className="splitterTop"></div>
        <div className="ml-12 p-10 h-full">
          <div className="max-w-3xl">
            {this.state.error ?
              "Article could not be found!"
              :
              <section className="text-gray-800 text-lg">
                <article dangerouslySetInnerHTML={{__html: markdown}}></article>
              </section>
            }

          </div>
        </div>
        <Footer type="splitter"/>
      </div>
    )
  }
}
