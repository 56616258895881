import React from "react";
import Lefthex from "../../components/Lefthex";
import Rightex from "../../components/Righthex";
import Menu from "../util/Menu";
import Animations from '../../components/animations/Animations';
import Footer from "../util/Footer";

const About = () => (
  <div className="h-full">
    <Animations />
    <div className="bg-gray-800 aboutSegment text-gray-800">
      <div className="flex">
        <div className="w-1/5">
        </div>
        <div className="w-3/5">
          <Menu />
        </div>
        <div className="w-1/5">
        </div>
      </div>
      <div className="aboutHexLeft hidden xl:block absolute">
        <Lefthex />
      </div>
      <div className="aboutHexRight hidden xl:block absolute">
        <Rightex />
      </div>
      <div className="innerCircle"></div>
      <div className="outerCircle"></div>
      <div className="centerImage w-32 h-32">
        <div className="flip-box">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <img alt="Leon Face Avatar" className="rounded-full" src="./assets/avatar-leon.png"></img>
            </div>
            <div className="flip-box-back">
              <img alt="Leon Face Real" className="rounded-full" src="./assets/leon.jpg"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="rotation pos1">
        <div className="inner big">
          <i className="fas fa-fish"></i>
          <span className="text-white absolute iconText hidden">Aquaristics</span>
        </div>
      </div>
      <div className="rotation pos2">
        <div className="inner small">
          <i className="fas fa-code"></i>
            <span className="text-white absolute iconText hidden">Development</span>
        </div>
      </div>
      <div className="rotation pos3">
        <div className="inner">
          <i className="fas fa-pencil-ruler"></i>
          <span className="text-white absolute iconText hidden">Design</span>
        </div>
      </div>
      <div className="rotationbig pos4">
        <div className="inner">
          <i className="fas fa-fish"></i>
          <span className="text-white absolute iconText hidden">Fishing</span>
        </div>
      </div>
      <div className="rotationbig pos5">
        <div className="inner big">
          <i className="fas fa-gamepad"></i>
          <span className="text-white absolute iconText hidden">Videogames</span>
        </div>
      </div>
      <div className="rotationbig pos6">
        <div className="inner">
          <i className="fas fa-bolt pl-1"></i>
          <span className="text-white absolute iconText hidden">Tesla</span>
        </div>
      </div>
      <div className="rotationbig pos7">
        <div className="inner big">
          <i className="fas fa-space-shuttle"></i>
          <span className="text-white absolute iconText hidden">Space</span>
        </div>
      </div>
      <div className="rotationbig pos8">
        <div className="inner small">
          <img alt="meditiation" src="./assets/meditation.svg"></img>
          <span className="text-white absolute iconText hidden">Meditation</span>
        </div>
      </div>
    </div>
    <div className="splitterTop"></div>
      <div>
        <div className="BackgroundImgWrapper">
          <img alt="Design" className="designicon" src="./assets/BrushSvg.png" />
          <img alt="Code" className="devicon" src="./assets/CodeSvg.svg" />
        </div>
        <div className="max-w-5xl mx-auto py-4 sm:py-32 sm:pb-8 px-4 relative mb-12">
          <div className="macTerminal rounded bg-gray-800">
            <div className="terminalHeader bg-gray-200 px-2 flex rounded-t">
              <div className="rounded-full terminalButton bg-red-600"></div>
              <div className="rounded-full terminalButton bg-yellow-600 ml-1"></div>
              <div className="rounded-full terminalButton bg-green-600 ml-1"></div>
            </div>
            <div className="terminalBody p-2 text-white">
              <p className="line-1 anim-typewriter terminalLine">Hello again!</p>
              <p className="line-2 anim-typewriter2 terminalLine"><u>Development</u> and <u>design</u> is my passion.</p><br/>
              <p className="line-3 anim-typewriter3 terminalLine">But, of course, there is more ...</p>
              <p className="line-4 anim-typewriter4 terminalLine">Here you can find the things that <i>inspire</i> me & that I <i>love</i>.</p>
              <p className="line-5 anim-typewriter5 terminalLine">I want to inspire others by sharing what I've learned.</p><br/>
              <p className="line-6 anim-typewriter6 terminalLine">Take a look around and find out more about me.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-5xl mx-auto py-4 sm:py-32 sm:pb-8 px-4 relative">
          <div className="flex flex-wrap mb-6 mt-4 items-center">
            <div className="w-full md:w-1/2 pb-8 px-6">
              <h1 className="text-4xl text-gray-800 font-bold">
                Tools and Skills
              </h1>
              <h3 className="text-gray-600">
                When looking back at my development career I pick up new tools no matter where I go.<br/>
                These are some of my daylie helpers or troublemakers… <br/><br/>
                You can also find some tool related posts in my blog.
              </h3>
            </div>
            <div className="w-full md:w-1/2 md:px-0 hidden md:block text-center">
              <img alt="My Skills" className="absolute skills_img" src="./assets/MySkills.svg"></img>
            </div>
          </div>
        </div>
      </div>
      <Footer type="splitter"/>
  </div>

);

export default About;


// Hey I am Leon {
//   a full Stack software developer and web designer.
//   This site is a collection of things that I am passionate about.
//   I'll write little articles to showcase what Ive learned over the past years.
// }
//
// Hi there! I am ...
// whoami
// Leon
// a passionate full stack developer and desginer.
// This website is full of things that i love and that inspire me
// (yes I have other hobbys than just coding)
// My main goal is to share what I've learned and inspire as many people as i can.
// You can look around and find out more about me here and check out my blog.
