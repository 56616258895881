import React from "react";
import HeaderSegment from './HeaderSegment';
import BlogSegment from './BlogSegment';
import ContactSegment from './ContactSegment';
import NewsletterSegment from './NewsletterSegment';
import Animations from '../../components/animations/Animations';
import Footer from '../util/Footer';


const Home = () => (
  <div className="ApplicationWrapper">
    <Animations />
    <HeaderSegment />
    <BlogSegment />
    <NewsletterSegment />
    <ContactSegment />
    <Footer type="dark" />
  </div>
);

export default Home;
