import React from "react";
import SubscribeNewsletter from "../../components/SubscribeNewsletter";

const NewsletterSegment = () => (
  <div className="flex overflow-hidden mt-32">
    <div className="w-12 sm:w-1/5"></div>
    <div className="w-full sm:w-3/5">
      <h1 className="text-4xl font-bold animatedFadeIn">
        Interested in more?
      </h1>
      <h3 className="text-gray-600 animatedFadeIn">
        Subscribe to this little blog and get notified when a new article is published.<br/> See you on the other side…
      </h3>
      <div className="rounded-lg overflow-hidden mt-8 animatedFadeIn">
        <SubscribeNewsletter />
      </div>
    </div>
    <div className="w-12 sm:w-1/5"></div>
  </div>
);

export default NewsletterSegment;
