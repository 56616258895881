import React from "react";
import ArticleRenderer from "../../components/ArticleRenderer"
import articles from "../../articles/articleTeasers.json"
import {
  Link
} from "react-router-dom";

const BlogArticleTimeline = () => (
  <div>
    <div className="timeline overflow-hidden">
      <div className="timeLineBlogBefore hidden lg:block"></div>
      <div className="timelineBlogCenter hidden lg:block"></div>
      <div className="container mb-12 displayArticle">
        <Link to="/blog/website">
          <ArticleRenderer test="website" item={articles.website} />
        </Link>
      </div>
      <div className="blogBackgroundImg1 hidden lg:block" >
        <img alt="" src="./assets/backImg1.svg" className="w-64"></img>
      </div>
      <div className="container articleRight mb-4 displayArticle2">
        <Link to="/blog/office">
          <ArticleRenderer item={articles.office} />
        </Link>
      </div>
      <div className="blogBackgroundImg2 hidden lg:block">
        <img alt="" src="./assets/backImg2.svg" className="w-64"></img>
      </div>
      <div className="container articleLeft mb-4 displayArticle3">
        <Link to="/blog/fishtank">
          <ArticleRenderer item={articles.fishtank} />
        </Link>
      </div>
      <div className="container articleRight mb-4 displayArticle4">
        <Link to="/blog/greensock">
          <ArticleRenderer item={articles.greensock} />
        </Link>
      </div>
      <div className="hidden lg:h-12"></div>
      <div className="timelineEnd hidden lg:block"></div>
      <div className="float-right pr-8 mb-4">
        <button className="py-3 px-4 text-gray-700 font-semibold hover:text-blue-600">
          All Articles
          <i className="fas fa-arrow-circle-right ml-4"></i>
        </button>
      </div>
    </div>
  </div>
);

export default BlogArticleTimeline;
