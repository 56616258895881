import React from "react";
import BlogArticleTimeline from "./BlogArticleTimeline";

const BlogSegment = () => (
  <div className="blogSegment">
    <div className="flex">
      <div className="w-1/5"></div>
      <div className="w-3/5 mb-8">
        <div className="timeLineBlog hidden lg:block"></div>
        <div className="EntryAnimatedFadeIn">
          <h1 className="text-4xl text-gray-800 font-bold">
            Life Long learning...
          </h1>
          <h3 className="text-gray-600">My journey of programming, fun and the Skills I have archived along the way.</h3>
        </div>
      </div>
      <div className="w-1/5"></div>
    </div>
    <BlogArticleTimeline />
  </div>
);

export default BlogSegment;
