import React from "react";
import ContactForm from "../../components/ContactForm";
import Rightex from "../../components/Righthex";


const ContactSegment = () => (
  <div className="contactSegment bg-gray-800 overflow-hidden">
    <div className="splitterBottom"></div>
    <div className="flex pt-32">
      <div className="w-1/5"></div>
      <div className="w-3/5">
        <div className="contactIntro">
          <h1 className="text-4xl text-white font-bold animatedFadeIn">
            Like What I Do?
          </h1>
          <div className="flex flex-wrap mb-4">
            <div className="w-full lg:w-1/2 mt-8">
              <p className="text-gray-400 animatedFadeIn">Say Hi!  <span role="img" aria-label="hello">👋</span> <br/>
                Have I caught your interest or do you want to know more about one of my projects?<br/>
                Do you have any feedback for this website or want to grab a coffee with me?<br/>
                Or maybe you want to chat a bit about a shared interest.<br/>
              whatever it might be, I'm happy to hear from you!
              </p>
            </div>
            <div className="w-full lg:w-1/2 mt-8">
              <div className="lg:ml-8 mb-2 animatedFadeIn">
                <span className="text-xl mr-4 text-orange-custom w-4 lg:w-8 text-center inline-block">
                  <i className="fas fa-phone"></i>
                </span>
                <span className="text-gray-400">+49 176 410 411 80</span>
              </div>
              <div className="lg:ml-8 mb-2 animatedFadeIn">
                <span className="text-xl mr-4 text-orange-custom w-4 lg:w-8 text-center inline-block">
                  <i className="far fa-envelope"></i>
                </span>
                <a href="mailto:hey@leon-r.de" className="text-gray-400">hey@leon-r.de</a>
              </div>
              <div className="lg:ml-8 mb-2 animatedFadeIn">
                <span className="text-xl mr-4 text-orange-custom w-4 lg:w-8 text-center inline-block">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <a className="text-gray-400" target="_blank" rel="noopener noreferrer" href="https://www.google.de/maps/place/L%C3%BCneburg/@53.2390333,10.3525998,12z/data=!3m1!4b1!4m5!3m4!1s0x47b1ddc58c9ea45b:0xc60006ccd4f4fec3!8m2!3d53.2464214!4d10.4115179">Lüneburg in Germany</a>
              </div>
            </div>
          </div>
        </div>

        <ContactForm />
      </div>
      <div className="hidden xl:block relative">
          <Rightex type="bottom"/>
      </div>
      <div className="w-1/5"></div>
    </div>
  </div>
);

export default ContactSegment;
